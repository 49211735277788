import React from 'react'
import BoxStaticPageList from '../../../src/common/components/BoxStaticPageList/BoxStaticPageList'
import LayoutLanding from '../../../src/common/components/layouts/layoutLanding/LayoutLanding'
import { locale, t } from '../../../src/common/i18n'
import { getFullCompany } from '../../../src/common/utils/getCompany'

interface Props {
  styles: { [k: string]: string }
}

const sectionsEs = [
  {
    title: 'Servicios de Mecánica',
    items: [
      {
        title: 'Frenos de Coche',
        description: `Un elemento de seguridad que debe estar siempre en perfecto estado para garantizar una respuesta inmediata y eficiente. El sistema de frenado de un coche se compone de: el tambor o los discos de freno, el líquido de frenos y las pastillas.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/frenos-coche-elpaso2000.jpg`,
        route: 'coche.frenos',
      },
      {
        title: 'Correa de Distribución',
        description: `Su función es trasladar el movimiento desde el cigüeñal al árbol de levas. Es una corre fabricada de goma que se desgasta con el uso. Su sustitución se efectúa entre 85.000 km y 150.000 km o cada 8 - 12 años.  
        `,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/correa-distribucion-mantenimiento-elpaso2000.jpg`,
        route: 'coche.correa',
      },
      {
        title: 'Amortiguadores de Coche',
        description: `Mantienen el contacto de la rueda con la carretera. Unos amortiguadores en buen estado proporcionan al coche una mayor adherencia y contribuyen a la seguridad y comodidad al volante.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/amortiguador-coche-elpaso2000.jpg`,
        route: 'coche.amortiguadores',
      },
    ],
  },
  {
    title: 'Servicios de Mantenimiento',
    items: [
      {
        title: 'Cambio de neumáticos',
        description: `¿Necesitas unos neumáticos nuevos? Tenemos las mejores marcas al mejor precio. Nuestros técnicos especialistas sustituirán tus ruedas con un perfecto equilibrado y alineado. `,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/cambio-neumaticos-elpaso2000.jpg`,
        route: 'neumaticos.index',
      },
      {
        title: 'Cambio de aceite',
        description: `¿El manual de usuario de tu coche indica que se debe realizar un cambio de aceite? En El Paso 2000 disponemos de los mejores Packs de Cambio de Aceite y filtros para tu automóvil. `,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/cambio-aceite-coche-elpaso2000.jpg`,
        route: 'revisiones',
      },
      {
        title: 'Cambio de filtros',
        description: `Son fundamentales para el buen funcionamiento del coche y el confort de los pasajeros. Estos filtros son de aceite, aire, habitáculo y combustible. Se comprueba su estada en cada cambio de aceite.  `,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/cambio-filtro-aceite-coche-elpaso2000.jpg`,
        route: 'coche.filtros',
      },
      {
        title: 'Cambio de batería',
        description: `Una pieza fundamental en cualquier automóvil, la batería se encarga de la puesta en marcha de motor cuando accionas el contacto. Su fallo es una de las causas de avería más comunes. `,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/cambio-bateria-coche-elpaso2000.jpg`,
        route: 'coche.baterias',
      },
    ],
  },
  {
    title: 'Servicios de Puesta a Punto',
    items: [
      {
        title: 'Carga de aire acondicionado',
        description: `¿Enciendes el aire acondicionado, pero no sale frio? Quizás necesitas una carga de gas. En El Paso 2000 te ayudamos a solucionar este problema. Solicita cita previa y vuelve a disfrutar de aire fresco.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/carga-aire-acondicionado-coche-elpaso2000.jpg`,
        route: 'coche.aire_acondicionado',
      },
      {
        title: 'Escobillas Limpiaparabrisas',
        description: `Las escobillas se encargan de evacuar el agua del parabrisas. Si cuando activas el limpiaparabrisas hace ruido i le cuesta evacuar el agua, probablemente ha llegado el momento de sustituir las escobillas. `,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/escobilla-parabrisas-elpaso2000.jpg`,
        route: 'coche.escobillas',
      },
    ],
  },
]

const CocheEs = ({ styles }: Props) => (
  <LayoutLanding
    title="Servicios para tu coche"
    textRecomendation="Los elementos del coche se desgastan con su uso y el paso del tiempo. Cuando realizas la revisión controlas su estado que es imprescindible para una conducción segura. Si cumples con lo estipulado por los fabricantes ganarás en tranquilidad y seguridad."
    descriptionCTA="¿Necesitas hacerle el mantenimiento a tu coche? Todo lo que necesita tu vehículo lo encontrarás en EL PASO 2000. ¡Descúbrelos ahora!"
    linkButtonCTA="/cita-previa/"
    textButtonCTA="SOLICITAR CITA PREVIA">
    <React.Fragment>
      <p>
        En <strong>{getFullCompany()}</strong>
         estamos especializados en el
        <strong> mantenimiento de tu automóvil.</strong>. Disponemos de
        servicios de mecánica integral que ofrecen
        <strong> soluciones</strong> adaptadas a las necesidades de cada
        conductor y cada coche al <strong>mejor precio. </strong>
        Nos encargamos de realizar el cambio de aceite, frenos, amortiguadores,
        batería, filtros de motor…
      </p>
      <p>
        Te explicamos todos los servicios que encontrarás en los talleres
        mecánicos {getFullCompany()}.
      </p>
      {sectionsEs.map(section => (
        <BoxStaticPageList
          key={section.title}
          title={section.title}
          items={section.items}
        />
      ))}
    </React.Fragment>
  </LayoutLanding>
)

const CocheContents = ({ ...props }: Props) => <CocheEs {...props} />

export default CocheContents
